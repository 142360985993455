import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import './tooltip.scss'
import UserHover from './userHover'

const TooltipComponent = ({ children }) => {
  return (
    <Tooltip
      id="userHoverTooltip"
      trigger={['hover']}
      overlay={
        <div className="tooltipUserHover">
          <UserHover />
        </div>
      }
      placement="top"
      showArrow={false}
    >
      {children}
    </Tooltip>
  )
}

export default TooltipComponent

import { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { PagePopup } from '../../..'
import banner from '../../../assets/holder/banner_1.jpg'
import DragAndDrop from '../dragAndDrop/dragAndDrop'
import './spaceBar.scss'

const SpaceBar = () => {
  const [flippedPage, setFlippedPage] = useState(false)
  const collapsePopupPage = () => {
    setFlippedPage(!flippedPage)
  }
  return (
    <>
      {flippedPage && <PagePopup collapsePopup={collapsePopupPage} />}
      <div
        className="content_padding channel_bar_content"
        style={{ paddingTop: 10 }}
        id="channel_bar_content"
      >
        <div className="scrollable" style={{ paddingTop: 3 }}>
          <div
            className="space_banner"
            id="space_banner"
            style={{ backgroundImage: 'url(' + banner + ')' }}
          >
            <span className="badge badge-dark">
              <i className="fa-solid fa-hourglass-start" />
              10 Days Left
            </span>
          </div>
          <div className="space_channel_content_box">
            <div className="stat_title">Space members</div>
            <div
              className="progress"
              data-tooltip-id="member_stats"
              data-tooltip-content="25/100 users (25%)"
              data-tooltip-place="top-end"
            >
              <Tooltip style={{ fontSize: 10 }} id="member_stats" />
              <div
                className="progress_bar"
                role="progressbar"
                style={{ width: '25%' }}
                aria-valuenow={25}
                aria-valuemax={100}
              ></div>
            </div>
            <div className="stat_title">Total interactions</div>
            <div
              className="progress"
              data-tooltip-id="interaction_stats"
              data-tooltip-content="25K/25K interactions (100%)"
              data-tooltip-place="top-end"
            >
              <Tooltip style={{ fontSize: 10 }} id="interaction_stats" />
              <div
                className="progress_bar"
                role="progressbar"
                style={{ width: '100%' }}
                aria-valuenow={110}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <DragAndDrop handleCategoryAddition={collapsePopupPage} />
        </div>
      </div>
    </>
  )
}

export default SpaceBar

const CompassSpace = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {`
          .location-icon {
            fill: currentColor;
          }
        `}
    </style>
    <g clipPath="url(#clip0_4103_6819)">
      <path
        className="location-icon"
        d="M20.9677 20.9668C19.8447 22.0899 19.8447 23.9101 20.9677 25.0323C22.0899 26.1553 23.911 26.1553 25.0332 25.0323C26.1562 23.9092 26.1562 22.089 25.0332 20.9668C23.9101 19.8447 22.0899 19.8447 20.9677 20.9668ZM23 0.71875C10.6941 0.71875 0.71875 10.6941 0.71875 23C0.71875 35.3059 10.6941 45.2812 23 45.2812C35.3059 45.2812 45.2812 35.3059 45.2812 23C45.2812 10.6941 35.3059 0.71875 23 0.71875ZM34.3329 14.0201L28.4059 26.9891C28.1192 27.6162 27.6162 28.1192 26.9891 28.4059L14.021 34.3329C12.5251 35.0166 10.9834 33.4749 11.6671 31.979L17.595 19.01C17.8817 18.3829 18.3846 17.8799 19.0118 17.5932L31.9799 11.6662C33.4758 10.9834 35.0166 12.5242 34.3329 14.0201Z"
        fill="currentColor"
      />
      <circle cx="36.5" cy="36.5" r="9.5" fill="#FEFEFE" />
      <path
        className="location-icon"
        d="M37.5714 30.125C37.5714 29.5027 37.0607 29 36.4286 29C35.7964 29 35.2857 29.5027 35.2857 30.125V35.1875H30.1429C29.5107 35.1875 29 35.6902 29 36.3125C29 36.9348 29.5107 37.4375 30.1429 37.4375H35.2857V42.5C35.2857 43.1223 35.7964 43.625 36.4286 43.625C37.0607 43.625 37.5714 43.1223 37.5714 42.5V37.4375H42.7143C43.3464 37.4375 43.8571 36.9348 43.8571 36.3125C43.8571 35.6902 43.3464 35.1875 42.7143 35.1875H37.5714V30.125Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4103_6819">
        <rect width="46" height="46" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CompassSpace

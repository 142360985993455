import AvatarImg from '../assets/holder/avatar_demo.png'
import './mentions.scss'

const emitInsertMention = (detail) => {
  document.dispatchEvent(new CustomEvent('insertMention', { detail }))
}

const Mention = ({ mention }) => {
  const onClick = () => {
    emitInsertMention({ data: mention })
  }
  return (
    <div className="mention" onClick={onClick}>
      <img
        src={AvatarImg}
        alt="Avatar"
        className="avatar"
        style={{ width: 27, height: 24 }}
      />
      <span>{mention}</span>
    </div>
  )
}
const mentionsArray = [
  'John Doe',
  'Jack Grealish',
  'John Nickelson',
  'Rupert Low',
  'David Dr',
]

const Mentions = ({ query }) => {
  const filteredMentions = mentionsArray.filter((mention) =>
    mention.toLowerCase().includes(query.toLowerCase())
  )
  const slicedFilteredMentions =
    filteredMentions.length > 3
      ? filteredMentions.slice(0, 3)
      : filteredMentions
  return (
    <div
      className="mentions"
      style={{
        top: `-${(slicedFilteredMentions.length - 1) * 40}px`,
      }}
    >
      {slicedFilteredMentions.map((mention) => (
        <Mention key={mention} mention={mention} />
      ))}
    </div>
  )
}

export default Mentions

import 'bootstrap/dist/css/bootstrap.css'
import ReactDOM from 'react-dom/client'
import App from './App'
import { RegistrationProvider } from './stores/registrationContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <RegistrationProvider>
    <App />
  </RegistrationProvider>
)
